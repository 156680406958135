html {
  height: 100%;
}

body {
  font-family: Arial, Helvetica, sans-serif;
  height: 100vh;
  margin: 0;
  webkit-font-smoothing: antialiased;
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 300;
}

#root {
  height: 100%;
}

a {
  color: #5334F5;
  font-weight: 600;
  text-decoration: none;
}

p {
    color: rgba(0,0,0,0.8);
    text-align: left;
    margin: 0;
}

body {
  background: url('assets/backgroundImage.jpg') no-repeat center right fixed;
  height: 100%;
  padding: 0;
  margin: 0;
  /* Preserve aspet ratio */
  min-width: 100%;
  min-height: 100%;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}


.HeroGroup {
  padding: 0;
  margin: 0;
  align-items: center;
  justify-content: center;
}


@media (max-width: 640px) {
  .HeroGroup {
    padding: 15% 8%;
  }
}


.HeroLogo {
  max-width: 270px;
  margin: 0 auto;
  padding: 30vh 0 0;
}
      
@media (max-width: 640px) {
  .HeroLogo {
    width: 180px;
    padding: 15vh 0 0;
  }
}

@media (max-width: 320px) {
  .HeroLogo {
    width: 180px;
    padding: 0 0 0;
  }
}


.HeroComingSoon {
    max-width: 770px;
    margin: 3rem auto;
}

.makeStyles-paper-1:focus {
  outline: none;
}

.PopUp {
  max-width: 800px;
  background-color: #fff;
  margin: 2rem auto;
}


.PopUpGroup {
  padding: 3rem;
  align-items: center;
  justify-content: center;
}

@media (max-width: 640px) {
  .PopUpGroup {
    padding: 1rem;
  }
}

.PopUpTextGroup {
  margin-bottom: 2rem;
}

.CloseIcon {
  position: absolute;
  right: 40px;
  top: 40px;
  cursor: pointer;
}


.PopUp h2,
.PopUpHeadline {
  font-size: 1.1rem;
  font-weight: 600;
  color: rgba(0,0,0,0.8);
  text-align: left;
  margin: 0;
}

.PopUp p {
  color: rgba(0,0,0,0.8);
  text-align: left;
}

.Footer{
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

.Footer p {
  color: rgba(135,135,135,0.8);
  text-align: center;
}

.Footer a {
  cursor: pointer;
  font-weight: normal;
  text-align: left;
  display: inline;
}
